require("./src/utils/globals.scss");
const { wrapRootElement } = require("./wrapRootElement");
const scrollToElement = require("scroll-to-element");

exports.wrapRootElement = wrapRootElement;

exports.onRouteUpdate = ({ location }) => {
  checkHash(location);
};

const checkHash = location => {
  let { hash } = location;
  if (hash) {
    //   adding the setTimeout pushed it to the end of the call stack & makes it work on the "about page"
    setTimeout(() => {
      scrollToElement(hash, {
        offset: -100,
        duration: 1000
      });
    }, 1);
  }
};
