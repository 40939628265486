import React from "react";
// import { bool } from "prop-types";

const LanguageContext = React.createContext();
export default LanguageContext;

export class LanguageContextProvider extends React.Component {
  state = {
    languages: ["en", "es"],
    currentLanguage: "en",
    modalOpen: false,
    modalType: null,
    types: ["volunteer", "sponsor", "feedback", "consent"], // Modal will render the volunteer or sponsor form, or any custom success message from Gravity forms
    modalData: null,
    formValues: {}
  };

  setLanguages = languages => {
    this.setState({ languages });
  };

  setModal = (boolean, type, data) => {
    const typeValid = this.state.types.includes(type);
    this.setState({
      modalOpen: typeValid ? boolean : false,
      modalType: typeValid ? type : null,
      modalData: typeValid ? data : null // this is for WYSIWYG messages only
    });
  };

  setFormValues = data => {
    this.setState({
      formValues: {
        ...data
      }
    });
  };

  setCurrentLanguage = language => {
    this.setState({ currentLanguage: language });
  };

  render() {
    const value = {
      // values
      languages: this.state.languages,
      currentLanguage: this.state.currentLanguage,
      modalOpen: this.state.modalOpen,
      modalType: this.state.modalType,
      modalData: this.state.modalData,
      formValues: this.state.formValues,
      // methods
      setLanguages: this.setLanguages,
      setCurrentLanguage: this.setCurrentLanguage,
      setModal: this.setModal,
      setFormValues: this.setFormValues
    };

    return (
      <LanguageContext.Provider value={value}>
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}
